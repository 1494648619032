import {useState} from 'react'
const  ShopItemBandana = ({data, setSellectedItem})=>{
    
    const [sellectedPrev, setSellectedPrev] = useState(0);

    let prevContent = "";
    prevContent = data.images.map((item, index)=>{
        return <div key={index} className="photos-box-prev"
            onClick={()=>setSellectedPrev(index)}><img className="photos-box-img-prev" src={item}></img></div>
    })

    return (
        <div className='shop-item-card'>
            <h2>{data.title}</h2>
            <div className="shop-item-photos-box">
                <div className="photos-box-main">
                    <img className="photos-box-img" src={data.images[sellectedPrev]} ></img>
                </div>
                <div className="photos-box-prev-container">
                    {prevContent}
                </div>
                
            </div>
            <h3>{data.short1}</h3>
            <h4 dangerouslySetInnerHTML={{__html: data.short2}}></h4>
            {/* <a>Czytaj dalej...</a> */}
            <button className='btn' style={{marginLeft:'30px', marginTop:'10px'}}
                onClick={()=>setSellectedItem(data)}>Zobacz szczegóły</button>
        </div>
    )
}
export default ShopItemBandana;