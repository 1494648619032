import Cookies from "../Components/Cookies";
import Footer from "../Components/Footer";
import Shop from "../Components/Shop";

const MainPage = ({ user }) => {


    if (process.env.NODE_ENV === 'development') {
        console.log("Dev mode", process.env);
    }

    return (
        <div className="page">

            <div className="page-header">
                <div className="page-header-content">
                    <div className="header-logo">
                        <div className="header-logo-image"></div>
                        <div>
                            <h1 className="header-title1">Dla Pieska</h1>
                            <h1 className="header-title2">i Kotka</h1>
                        </div>
                    </div>
                    <div className="header-promo">
                        <div>
                            <h2>Zadbaj aby Twój zwierzak zawsze wrócił do domu,
                                nie rezygnując ze swojej prywatności.</h2>

                            <a className="find-more" href="czyjazguba.html">Dowiedz się więcej...</a>
                        </div>

                    </div>
                    <div className="header-promo-shop">
                        <div>
                            <a href="#shop">Sprawdź stylowe i zwiększające bezpieczeństwo
                                Twojego pupila artykuły w naszym sklepie...

                                <div className="header-promo-shop-20">
                                    Ochrona najlepszego przyjaciela
                                    już od
                                    <h3>0,20zł</h3>
                                    na dzień.
                                </div>
                               <div className="header-promo-shop-20-add">
                                    Tak, to tylko 20 groszy
                               </div>
                            </a>
                        </div>


                    </div>


                </div>
            </div>
            <div id='shop'>
                <div className="shop-title">
                    <h1>Sklep dla pieska i kotka</h1>
                    <div style={{ width: '80px', height: '80px' }} className="header-logo-image"></div>
                </div>
                <Shop user={user}></Shop>
            </div>
            <Footer></Footer>
            <Cookies></Cookies>

        </div>
    );
}

export default MainPage;