import { useState } from "react";

const Cookies = () => {

    const [ok, setOk] = useState(false);

    const okHandler = () => {
        setOk(true);
    }

    if (ok) {
        return "";
    }

    return (
        <div className="cookes-container">
            <div className="cookies-content">
                <div>
                    Ta strona wykorzystuje pliki cookies w celu zapewnienia prawidłowego jej działania.
                    <br />Więcej dowiesz się w naszej
                    <a href="pp.pdf"> polityce prywatności </a>.
                </div>
                <button onClick={okHandler}>Rozumiem</button>
            </div>
        </div>
    );
}

export default Cookies;