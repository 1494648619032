import { useEffect, useState } from 'react';
import { login } from '../Api/userApi';

const LoginForm = (props) => {
  const [user, setUser] = useState({ email: "", pass: "" });
  const [err, setErr] = useState({ email: false, pass: false });

  const onChangeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const loginHandler = async (e) => {
    e.preventDefault();
    const tmpErr = { email: false, user: false };

    if (validateEmail(user.email) === null) {
      tmpErr.email = true;
      setErr(tmpErr);
      return;
    }

    if (user.pass.length < 3) {
      tmpErr.pass = true;
      setErr(tmpErr);
      return;
    }
    setErr(tmpErr);

    const res = await login(user);
    if (res === null) {
      props.setWarning(
        {
          header: "Błąd logowania",
          content: "Spróbuj późnej",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
      return;
    } else if (parseInt(res.errorCode) === 1) {
      if (parseInt(res.payload.id) === 0) {
        props.setWarning(
          {
            header: "Błąd logowania",
            content: "Nieprawidłowy email lub hasło",
            btnHandler: () => props.setWarning(null),
            btnLabel: "ok"
          }
        );
      }
      if(process.env.NODE_ENV === 'development'){
        console.log("Set user", res.payload);
      }
      props.setUser(res.payload);
    } else {
      props.setWarning(
        {
          header: "Błąd serwera",
          content: "Spróbuj późnej",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <>
      <div className="container">
        <div className="form-box box">
          <header>Logowanie</header>
          <hr />

          <form onSubmit={loginHandler}>
            <div className="form-box_">

              {err.email ? <div className='err-info'><h5 >popraw adres email</h5></div> : ""}
              <div className="input-container">

                <i className="fa fa-envelope icon"></i>
                <input className="input-field" type="text" placeholder="Adres email" name="email"
                  onChange={onChangeHandler} value={user.email} />
              </div>

              {err.pass ? <div className='err-info'><h5 >wpisz hasło</h5></div> : ""}
              <div className="input-container">
                <i className="fa fa-lock icon"></i>
                <input className="input-field password" type="password" placeholder="Hasło" name="pass"
                  onChange={onChangeHandler} value={user.pass} />
                {/* <i className="fa fa-eye icon toggle"></i> */}
              </div>


            </div>
            <center><input type="submit" name="register" id="submit" value="Zaloguj"
              className="btn btn-login" /></center>


            <div className="links">
              Nie masz konta? <a onClick={() => props.setSignUp(true)}>zarejestruj się</a>
            </div>
            <div className="links">
              Nie pamiętasz hasła? <a onClick={() => props.setRecovery(true)}>odzyskaj</a>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default LoginForm;