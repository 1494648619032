import { useEffect, useState } from "react";
import ShopItemBandana from "./ShopItemBandana";
import ShopItemDetails from "./ShopItemDetails";
import OrderPanel from "./OrderPanel";
import { getProducts } from "../Api/userApi";


// const items = [
//     {
//         id: 1, title: "Bandana duża 1", price:"14990", delivery:"1490",
//         images: ["https://johndog.pl/wp-content/uploads/2019/10/Apaszka-John-Dog-Tokyo_9JDP007ZD2585_K_1.jpg",
//             "https://johndog.pl/wp-content/uploads/2019/10/Apaszka-John-Dog-Tokyo_9JDP007ZD2585_K_3.jpg",
//             "https://johndog.pl/wp-content/uploads/2019/10/Apaszka-John-Dog-Tokyo_9JDP007ZD2585_K_2.jpg"],
//         short1: "APASZKA DLA PSA JOHN DOG TOKYO",
//         short2: "Chusta wykonana z miękkiego materiału, o wymiarach 72 cm x 50 cm x 50 cm",
//         details: "Chusta wykonana z miękkiego materiału. Ma kształt trójkąta o wymiarach 72 cm x 50 cm x 50 cm, więc można ją dopasować to każdego psiaka. Jest tak lekka, że Twój pupil nie poczuje, że coś zdobi jego szyję. Gustowny dodatek z autorskim wzorem John Doga w stylu Tokyo.",

//     },
//     {
//         id: 2, title: "Bandana duża 2", price:"16990", delivery:"1500",
//         images: ["https://johndog.pl/wp-content/uploads/2019/10/Apaszka-John-Dog-Tokyo_9JDP007ZD2585_K_1.jpg",
//             "https://johndog.pl/wp-content/uploads/2019/10/Apaszka-John-Dog-Tokyo_9JDP007ZD2585_K_3.jpg",
//             "https://johndog.pl/wp-content/uploads/2019/10/Apaszka-John-Dog-Tokyo_9JDP007ZD2585_K_2.jpg"],
//         short1: "APASZKA DLA PSA JOHN DOG TOKYO",
//         short2: "Chusta 2 wykonana z miękkiego materiału, o wymiarach 72 cm x 50 cm x 50 cm",
//         details: "Chusta 2 wykonana z miękkiego materiału. Ma kształt trójkąta o wymiarach 72 cm x 50 cm x 50 cm, więc można ją dopasować to każdego psiaka. Jest tak lekka, że Twój pupil nie poczuje, że coś zdobi jego szyję. Gustowny dodatek z autorskim wzorem John Doga w stylu Tokyo.",
//         sizes: [{ id: 1, txt: 'S mały długość 15cm obwód 30-35cm' }, { id: 2, txt: 'M mały długość 15cm obwód 30-35cm' },
//         { id: 3, txt: 'L mały długość 15cm obwód 30-35cm' }, { id: 4, txt: 'XL mały długość 15cm obwód 30-35cm' }],
//         personalisationName_: true,
//     }
// ]
const Shop = ({user}) => {

    const [sellectedItem, setSellectedItem] = useState(null);
    const [order, setOrder] = useState(null);
    const [items, setItems] = useState([]);
    

    const newOrderHandler = (orderData) => {
        if(process.env.NODE_ENV === 'development'){
            console.log('new order', orderData);
        }
        setOrder(orderData);
    }

    const selectItemHandler=(data)=>{
        if(process.env.NODE_ENV === 'development'){
            console.log('set sellected:', data);
        }
        setSellectedItem(data);
    }

    const itemsSorted = items.sort((a,b)=>{
        return a.price - b.price;
    });
    let shopContent = "";
    shopContent = itemsSorted.map((i, index)=>{
        return (
            <ShopItemBandana key={index} data={i} setSellectedItem={selectItemHandler}></ShopItemBandana>
        )
    })

    useEffect(()=>{
        async function loadItems(){
            const res = await getProducts(null);

            if(res !== null && res.errorCode===1){
                setItems(res.payload);
            }
        } 
        loadItems();
    }, []);

    

    if (order !== null) {
        return(
            <><OrderPanel order={order} user={user}
            setSellectedItem={setSellectedItem} setOrder={setOrder}></OrderPanel></>
        )
    }

    if (sellectedItem === null) {
        return (
            <div className="shop">
                {shopContent}
            </div>
        )
    } else {
        return (
            <>
                <ShopItemDetails sellectedItem={sellectedItem} setSellectedItem={setSellectedItem}
                    newOrderHandler={newOrderHandler} user={user}></ShopItemDetails>
            </>
        )
    }
}

export default Shop;