import { useState } from "react";
import { signup } from '../Api/userApi';

const RegistrationForm = (props) => {
  const [user, setUser] = useState({ name: "", email: "", pass: "", cpass: "", consentReg:false,
    consentNewsletter: false });
  const [warningContent, setWarningContent] = useState(null);
  const [err, setErr] = useState({ name: false, email: false, pass: false, passConf: false ,
    errConsentReg:false});

  const onChangeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }
  const checkRegHandler = () => {
    setUser({...user, consentReg:!user.consentReg} );
  }
  const checkNewsletterHandler = () => {
    setUser({...user, consentNewsletter:!user.consentNewsletter} );
  }

  const signupHandler = async (e) => {
    e.preventDefault();

    const tmpErr = { name: false, email: false, pass: false, passConf: false };

    if (user.name.length < 3 || user.name.length > 10 || validateName(user.name) === false) {
      tmpErr.name = true;
      setErr(tmpErr);
      return;
    }

    if (validateEmail(user.email) === null) {
      tmpErr.email = true;
      setErr(tmpErr);
      return;
    }

    if (user.pass.length < 8 || user.pass.length > 16 || validatePass(user.pass) === null) {
      tmpErr.pass = true;
      setErr(tmpErr);
      return;
    }

    if (user.pass !== user.cpass) {
      tmpErr.passConf = true;
      setErr(tmpErr);
      return;
    }

    if(user.consentReg !== true){
      tmpErr.consentReg = true;
      setErr(tmpErr);
      return;
    }

    const res = await signup(user);

    if (res === null) {
      props.setWarning(
        {
          header: "Błąd rejestracji",
          content: "Spróbuj późnej",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
      return;
    } else if (parseInt(res.errorCode) === 111) {
      props.setWarning(
        {
          header: "Adres email zajęty",
          content: "Istnieje już konto założone na podany adres, jeśli korzystasz z tego adresu możesz odzyskać dostęp do konta ",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
    } else if (parseInt(res.errorCode) === 112) {
      props.setWarning({
        header: "Błąd rejestracji",
        content: "Spróbuj późnej",
        btnHandler: () => props.setWarning(null),
        btnLabel: "ok"
      }
      );
    } else if (parseInt(res.errorCode) === 1) {
      props.setUser(res.payload);
      props.setSignUp(false);
    } else {
      props.setWarning(
        {
          header: "Błąd serwera",
          content: "Spróbuj późnej",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
    }



  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateName = (name) => {
    const cH = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'W', 'X', 'Y', 'Z', 'Q', 'V'];
    const cL = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'w', 'x', 'y', 'z', 'q', 'v'];
    const cD = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const len = name.length;
    for (let i = 0; i < len; i++) {
      if (cH.includes(name[i]) === false && cL.includes(name[i]) === false && cD.includes(name[i]) === false) {
        return false
      }
    }
    return true;
  }
  const validatePass = (pass) => {
    const cH = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'W', 'X', 'Y', 'Z', 'Q', 'V'];
    const cL = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'w', 'x', 'y', 'z', 'q', 'v'];
    const cD = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const len = pass.length;
    let isCH = false;
    let isCL = false;
    let isCD = false;
    let found = false;
    for (let i = 0; i < len; i++) {
      found = false;
      if (cH.includes(pass[i]) === true) {
        isCH = true;
        found = true;
      }
      if (cL.includes(pass[i]) === true) {
        isCL = true;
        found = true;
      }
      if (cD.includes(pass[i]) === true) {
        isCD = true;
        found = true;
      }
      if (!found) {
        return false;
      }
    }
    if (isCD && isCH && isCL) {
      return true;
    }
    return false;
  }



  return (
    <>
      <div className="container">
        <div className="form-box box">
          <header>Rejestracja</header>
          <hr />

          <form onSubmit={signupHandler}>
            <div className="form-box_">
              {err.name ? <div className="err-info"><h5 >Podaj nazwę dla konta (od 3 do 10 znaków, może zawierać małe i wielkie litery oraz cyfry)</h5></div> : ""}
              <div className="input-container">
                <i className="fa fa-user icon"></i>
                <input className="input-field" type="text" placeholder="Imię" name="name"
                  onChange={onChangeHandler} value={user.name} />
              </div>
              {err.email ? <div className="err-info"><h5 >Popraw adres email</h5></div> : ""}
              <div className="input-container">
                <i className="fa fa-envelope icon"></i>
                <input className="input-field" type="text" placeholder="Adres Email" name="email"
                  onChange={onChangeHandler} value={user.email} />
              </div>
              {err.pass ? <div className="err-info"><h5 >Wpisz hasło od 8 do 16 znaków, może zawierać małe
                i wielkie litery (bez polskich znaków) oraz cyfry. Powinno zawierać przynajmniej jedną małą literę, jedną wielką
                literę i jedną cyfrę</h5></div> : ""}
              <div className="input-container">
                <i className="fa fa-lock icon"></i>
                <input className="input-field password" type="password" placeholder="Hasło" name="pass"
                  onChange={onChangeHandler} value={user.pass} />
                {/* <i className="fa fa-eye icon toggle"></i> */}
              </div>
              {err.passConf ? <div className="err-info"><h5>Wpisane hasło nie jest zgodne powyższym</h5></div> : ""}
              <div className="input-container">
                <i className="fa fa-lock icon"></i>
                <input className="input-field" type="password" placeholder="Potwierdź Hasło" name="cpass"
                  onChange={onChangeHandler} value={user.cpass} />
                {/* <i className="fa fa-eye icon"></i> */}
              </div>




              {err.consentReg ? <div className="err-info"><h5 >Zaznaczenie tego pola jest 
                 niezbędne do rejestracji</h5></div> : ""}
              <div className="input-container input-container-consent">
                <input className="input-field consent-checkbox" type="checkbox" name="consent"
                  onChange={checkRegHandler} checked={user.consentReg} />
                <h5 style={{ fontWeight: 200, fontSize: '12px' }}>
                  Oświadczam iż, zapoznałam/em się z treścią
                  <a href="regulamin.html"> regulaminu </a> oraz
                  <a href="img/pp.pdf"> polityki prywatności </a>
                  serwisu internetowego dlapieska.com i akceptuję zawarte w nich postanowienia.
                </h5>
              </div>

              <center><input type="submit" name="register" id="submit" value="Rejestruj" className="btn" /></center>
            
              
              <div style={{marginTop:20, marginBottom:5, fontSize:14}}>Jeśli wyrazisz zgodę na zapis do newslettera 
              otrzymasz <strong>dodatkowe 2 miesiące dostępu </strong>
                 do&nbsp;systemu 
                <a href="czyjazguba.html">&nbsp;Czyja zguba</a>
              </div>
              <div className="input-container input-container-consent">
                <input className="input-field consent-checkbox" type="checkbox" name="consent"
                  onChange={checkNewsletterHandler} checked={user.consentNewsletter} />
                <h5 style={{ fontWeight: 200, fontSize: '12px' }}>
                Wyrażam zgodę na otrzymywanie od DlaPieska.com (Dariusz Widłak Pęcław 91 05-530)
                 drogą elektroniczną na podany przeze mnie adres e-mail informacji handlowych, 
                 dotyczących produktów oferowanych przez sklep DlaPieska.com. 
                 Zgoda ma charakter dobrowolny. Zgodę można wycofać w dowolnej chwili.

                  Więcej informacji znajdziesz w naszym 
                  <a href="regulamin.html"> regulaminie </a> oraz
                  <a href="pp.pdf"> polityce prywatności </a>
                  

                </h5>
              </div>

            </div>
            
            

            <div className="links">
              Masz już konto? <a onClick={() => props.setSignUp(false)}>Zaloguj się</a>
            </div>
            
          </form>
        </div>
      </div>
    </>
  )
}

export default RegistrationForm;