
import { useState } from "react";
import { putOrder } from "../Api/userApi";

const OrderPanel = ({order, user, setSellectedItem, setOrder}) => {
    const [client, setClient] = useState({
        firstName: "", lastName: "",
        city: "", strit: "", zip: "", phone: "", consent:false, consent1:false, consent2:false,
    })
    const [err, setErr] = useState({
        firstName: false, lastName: false, city: false, strit: false, homeNum:"",
        zip: false, phone: false, consent:false,
    });

    const [orderStatus, setOrderStatus] = useState({status:0, msg:""});

    const onChangeHandler = (e) => {
        setClient({ ...client, [e.target.name]: e.target.value });
    }

    const checkHandler = () => {
        setClient({...client, consent:!client.consent} );
    }

    const putOrderHandler = async (e)=> {
        e.preventDefault();
        const tmpErr = {firstName: false, lastName: false, city: false, strit: false, homeNum:"", zip: false, phone: false, consent:false};
        let validationError = false;
        if(client.firstName.length < 2){
            validationError = true;
            tmpErr.firstName = true;
        }
        if(client.lastName.length < 2){
            validationError = true;
            tmpErr.lastName = true;
        }
        if(client.strit.length < 1){
            validationError = true;
            tmpErr.strit = true;
        }
        if(client.zip.length < 5){
            validationError = true;
            tmpErr.zip = true;
        }
        if(client.city.length < 2){
            validationError = true;
            tmpErr.city = true;
        }
        if(client.phone.length < 6){
            validationError = true;
            tmpErr.phone = true;
        }

        if(client.consent !== true){
            validationError = true;
            tmpErr.consent = true;
        }

        setErr(tmpErr);
        if(validationError){
            return;
        }

        const tmpOrder = {itemId:order.item.id, params: order.paramsform, client:client};
        const res = await putOrder(tmpOrder);
        
        if(res !== null && parseInt(res.errorCode)===1){
            setOrderStatus({status:1, msg:res.payload});
        }if(res !== null && parseInt(res.errorCode)!==1){
            setOrderStatus({status:1, msg:"Brak możliwości zakupu"});
        }
    }

    let sizesContent = "";
    if (order.item.sizes !== undefined && order.item.sizes.length > 0) {
        const s = order.item.sizes.filter(
            (item) => { return parseInt(item.id) === parseInt(order.paramsform.size) })[0];
        sizesContent = (<>{s.txt}</>);
    }

    const priceStr = (order.item.price/100).toFixed(2) + " zł";
    const deliveryPriceStr = (order.item.deliveryPrice / 100).toFixed(2) + " zł";
    const totalPrice = order.item.price + order.item.deliveryPrice ;
    const totalPriceStr = (totalPrice/100).toFixed(2) + " zł";

    const closeHandler=()=>{
        setSellectedItem(null);
        setOrder(null);
    } 

    let orderConfirmation = ""
    if(orderStatus.status > 0){
        const orderNumStr = user.code + "/" + orderStatus.id;
        orderConfirmation = (
           
                    <div className="order-confirmation-container">
                        <div dangerouslySetInnerHTML={{ __html: orderStatus.msg }}></div>
                        <button style={{backgroundColor:'#555', margin:'20px'}} className="btn"
                            onClick={closeHandler}>Zamknij</button>
                    </div>
                
        )
    }

    const orderForm = (
        <>
            <div className="shop-client-form">
                    <h2 style={{fontSize:20, marginLeft:40}}>Twoje dane</h2>
                    <form onSubmit={putOrderHandler}>
                        <div className="form-box">
                            {err.firstName ? <div className="err-info"><h5 >Podaj imię</h5></div> : ""}
                            <div className="input-container">
                                <i className="fa fa-user icon"></i>
                                <input className="input-field" type="text" placeholder="Imię" name="firstName"
                                    onChange={onChangeHandler} value={client.firstName} maxLength={15}/>
                            </div>
                            {err.lastName ? <div className="err-info"><h5 >Podaj nazwisko</h5></div> : ""}
                            <div className="input-container">
                                <i className="fa fa-user icon"></i>
                                <input className="input-field" type="text" placeholder="Nazwisko" name="lastName"
                                    onChange={onChangeHandler} value={client.lasetName} maxLength={51}/>
                            </div>
                            {err.strit ? <div className="err-info"><h5 >Podaj adres (ulica i numer domu/mieszkania)</h5></div> : ""}
                            <div className="input-container">
                                <input className="input-field" type="text" placeholder="Ulica i numer" name="strit"
                                    onChange={onChangeHandler} value={client.strit} maxLength={30}/>
                            </div>
                            {err.zip ? <div className="err-info"><h5 >Podaj kod pocztowy w formacie XX-XXX</h5></div> : ""}
                            <div className="input-container">
                    
                                <input className="input-field" type="text" placeholder="Kod pocztowy" name="zip"
                                    onChange={onChangeHandler} value={client.zip} maxLength={6}/>
                            </div>
                            {err.city ? <div className="err-info"><h5 >Podaj nazwę miejscowości</h5></div> : ""}
                            <div className="input-container">
                                <input className="input-field" type="text" placeholder="Miejscowość" name="city"
                                    onChange={onChangeHandler} value={client.city} maxLength={30}/>
                            </div>
                            <div className="input-container">
                                <input className="input-field" type="text" placeholder="Kraj" name="country"
                                   value="Polska" readOnly/>
                            </div>
                            {err.phone ? <div className="err-info"><h5 >Podaj numer telefonu niezbędny dla przesyłki kurierskiej</h5></div> : ""}
                            <div className="input-container">
                                <input className="input-field" type="text" placeholder="Numer telefonu" name="phone"
                                    onChange={onChangeHandler} value={client.phone} maxLength={10}/>
                            </div>
                        </div>
                    </form>
                </div>



                <div className="shop-client-form client-form-consent">
                    
                    <form >
                        <div className="form-box form-box-consent">
                            

                            {err.consent ? <div className="err-info"><h5 >Zaznaczenie tego pola jest niezbędne do realizacji zamówienia</h5></div> : ""}
                            <div className="input-container input-container-consent">
                                <input className="input-field consent-checkbox" type="checkbox"  name="consent"
                                    onChange={checkHandler} checked={client.consent} />
                                    <h5 style={{fontWeight:200, fontSize:'12px'}}>
                                Oświadczam iż, zapoznałam/em się z treścią 
                                <a href="regulamin.html"> regulaminu </a> 
                                oraz 
                                <a href="pp.pdf"> polityki prywatności </a>
                                serwisu internetowego dlapieska.com i akceptuję zawarte w nich postanowienia. 
                                
                                </h5>
                            </div>
                            
                           
                        </div>
                        {/* <center><input type="submit" name="register" id="submit" 
                        value="Zamawiam z obowiązkiem zapłaty" 
                        className="btn btn-order-fin"/></center> */}
                        <button className="btn btn-order-fin" style={{marginLeft:'32px'}}
                            onClick={putOrderHandler}>Zamawiam z obowiązkiem zapłaty</button>
                        <button className="btn shop-details-close-btn" onClick={closeHandler}
                        >Anuluj</button>

                    </form>
                </div>
        </>
    )

    return (
        <div className="shop">
            <div className="shop-order">
                
                <div className="shop-order-sumary">
                <h2 style={{marginBottom:5}}>Zamawiasz</h2>
                <hr style={{marginBottom:20}}/>
                <div><h3>{order.item.title}</h3></div>
                <div><img className="photos-box-img-prev" src={order.item.images[0]}></img></div>
                <div>{sizesContent}</div>
                <div>Imię: {order.paramsform.dogName}</div>
                <div className="price-line"><h4>Cena</h4><h3>{priceStr}</h3></div>
                <div className="price-line price-line-delivery"><h4>Koszt transportu</h4> <h3>{deliveryPriceStr}</h3></div>
                <div className="price-line price-line-total"><h4>Do zapłaty </h4><h3>{totalPriceStr}</h3></div>
                   
                </div>
                {orderStatus.status > 0 ? orderConfirmation : orderForm}

            </div>
        </div>
    )

}

export default OrderPanel;