import { useEffect, useState } from "react";
import { getMessages } from "../Api/userApi";

const MessageBox = ()=>{
    const [tab, setTab] = useState([]);

    useEffect(()=>{

        async function getMess(){
            const res = await getMessages();
            if(res === null){
                return;
            }else if(parseInt(res.errorCode) === 1){
                setTab(res.payload);
            }else{
              
            } 
            
        }
        getMess();
    },[])
    let tableContent = <tr><td colSpan={2}>Brak powiadomień</td></tr>;

    if(tab.length>0){
        tableContent = tab.map(item=>{
            return (<tr key={item.date}><td>{item.date}</td><td>{item.message}</td></tr>);
        })
    }
    return (
        <div className='messageBox'>
            <h2>Powiadomienia</h2>
            <hr/>
            <table>
                <thead>
                    <tr>
                        <th>Data dodania</th><th>Kontakt</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
        </div>
    )
}

export default MessageBox;