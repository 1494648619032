import { useState } from "react";
import { changePass} from '../Api/userApi';

const ChangePassForm = (props) => {
    const [user, setUser] = useState({ name: "", email: props.user.email, pass: "", cpass: "", npass:"" });
    const [warningContent, setWarningContent] = useState(null);
    const [err, setErr] = useState({ name: false, email: false, pass: false, newPass: false, passConf: false });
    
    const onChangeHandler = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const signupHandler = async (e) => {
        e.preventDefault();

        const tmpErr = { name: false, email: false, pass: false, newPass: false, passConf: false };

        if (user.pass.length < 3 || user.pass.length > 16 ) {
            tmpErr.pass = true;
            setErr(tmpErr);
            return;
        }

        if (user.npass.length < 8 || user.npass.length > 16 || validatePass(user.npass) === null) {
            tmpErr.newPass = true;
            setErr(tmpErr);
            return;
        }

        if (user.npass !== user.cpass) {
            tmpErr.passConf = true;
            setErr(tmpErr);
            return;
        }
        setErr(tmpErr);
       
        const res = await changePass(user);
        if(res === null){
            props.setWarning(
              { header:"Błąd serwera", 
              content:"Spróbuj późnej", 
              btnHandler:()=>props.setWarning(null), 
              btnLabel:"ok"}
              );
              return;
        }else if(parseInt(res.errorCode) === 101){  
            props.setWarning(
              { header:"Błąd zmiany hasła", 
              content:"Stare hasło jest nieprawidłowe", 
              btnHandler:()=>props.setWarning(null), 
              btnLabel:"ok"}
              );
          
        }else if(parseInt(res.errorCode) === 1){  
            props.setWarning(
              { header:"Hasło zmienione", 
              content:"", 
              btnHandler:()=>props.setWarning(null), 
              btnLabel:"ok"}
              );
              props.setPassHange(false);
          
        }else{
          props.setWarning(
            { header:"Błąd serwera", 
            content:"Spróbuj późnej", 
            btnHandler:()=>props.setWarning(null), 
            btnLabel:"ok"}
            );
        }
        
    }

   
    const validatePass = (pass) => {
        const cH = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'W', 'X', 'Y', 'Z', 'Q', 'V'];
        const cL = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'w', 'x', 'y', 'z', 'q', 'v'];
        const cD = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        const len = pass.length;
        let isCH = false;
        let isCL = false;
        let isCD = false;
        let found = false;
        for (let i = 0; i < len; i++) {
            found = false;
            if (cH.includes(pass[i]) === true) {
                isCH = true;
                found = true;
            }
            if (cL.includes(pass[i]) === true) {
                isCL = true;
                found = true;
            }
            if (cD.includes(pass[i]) === true) {
                isCD = true;
                found = true;
            }
            if (!found) {
                return false;
            }
        }
        if (isCD && isCH && isCL) {
            return true;
        }
        return false;
    }



    return (
        <>
            <div className="container">
                <div className="form-box box">
                    <header>Zmiana hasła</header>
                    <hr />

                    <form onSubmit={signupHandler}>
                        <div className="form-box_">

                            <div className="form-box-info" style={{ textAlign: 'center' }}>
                                <h3 >Podaj stare hasło lub kod jednorazowy.
                                </h3>
                            </div>
                            {err.pass ? <div className='err-info'><h5 >Podaj stare hasło lub kod jednorazowy</h5></div> : ""}
                            <div className="input-container">
                                <i className="fa fa-user icon"></i>
                                <input className="input-field" type="password" placeholder="Stare hasło" name="pass"
                                    onChange={onChangeHandler} value={user.pass} />
                            </div>
                           
                            {err.newPass ? <div className='err-info'><h5 >Wpisz hasło od 8 do 16 znaków, może zawierać małe
                                i wielkie litery (bez polskich znaków) oraz cyfry. Powinno zawierać przynajmniej jedną małą literę, jedną wielką
                                literę i jedną cyfrę</h5></div> : ""}
                            <div className="input-container">
                                <i className="fa fa-lock icon"></i>
                                <input className="input-field password" type="password" placeholder="Nowe hasło" name="npass"
                                    onChange={onChangeHandler} value={user.npass} />
                                {/* <i className="fa fa-eye icon toggle"></i> */}
                            </div>
                            {err.passConf ? <div className='err-info'><h5 >Wpisane hasło nie jest zgodne powyższym</h5></div> : ""}
                            <div className="input-container">
                                <i className="fa fa-lock icon"></i>
                                <input className="input-field" type="password" placeholder="Potwierdź nowe hasło" name="cpass"
                                    onChange={onChangeHandler} value={user.cpass} />
                                {/* <i className="fa fa-eye icon"></i> */}
                            </div>
                        </div>
                        <center><input type="submit" name="register" id="submit" value="Zmień" className="btn" /></center>


                       
                    </form>
                    <div className="links">
                        <a onClick={()=>props.setPassHange(false)}>Anuluj</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassForm;