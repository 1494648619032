const Footer = () =>{
    return (
        <div className="footer">
            <div className="footer-content">
                <div>
                <a href="regulamin.html">Regulamin</a>
                <a href="pp.pdf">Polityka prywatności</a>
                </div>
                <strong>kontakt@dlapieska.com</strong>
            </div>
        </div>
    )
}
export default Footer;