import { useState } from "react";

const ShopItemDetails = ({ sellectedItem, setSellectedItem, newOrderHandler, user }) => {
    const [form, setForm] = useState({ size: 1, dogName: '' });
    const [sellectedPrev, setSellectedPrev] = useState(0);
    const [err, setErr] = useState({ dogName: false });
    const [scale, setScale] = useState(false);

    let prevContent = "";
    prevContent = sellectedItem.images.map((item, index) => {
        return <div key={index} className="photos-box-prev"
            onClick={() => setSellectedPrev(index)}><img className="photos-box-img-prev" src={item}></img></div>
    })

    const addOrderHandler = () => {
        if (form.dogName.length < 2) {
            setErr({ dogName: true });
            return;
        }
        setErr({ dogName: false });
        const tmpItem = JSON.parse(JSON.stringify(sellectedItem));
        const tmpOrder = { item: tmpItem, paramsform: { ...form }, clientForm: null };
        newOrderHandler(tmpOrder);
    }

    const dogNameHandler = (e) => {
        setForm({ ...form, dogName: e.target.value })
    }

    let orderButton = (
        <div className="login-for-order-info">
            <h4>Aby zamówić musisz być zalogowany</h4>
        </div>
    );
    if (user.id > 0) {
        if (parseInt(sellectedItem.stock) > 0) {
            orderButton = (<button className=" shop-btn-order" onClick={addOrderHandler}>Zamów</button>);
        } else {
            orderButton = (
                <div className="login-for-order-info">
                    <h4>Brak na magazynie
                        <br />Napisz do nas aby spytać o dostępność
                    </h4>
                </div>
            );
        }
    }



    //const priceStr = sellectedItem.price.substring(0,sellectedItem.price.length-2) + "." +
    //    sellectedItem.price.substring(sellectedItem.price.length-2) + " zł";

    const priceStr = (sellectedItem.price / 100).toFixed(2) + " zł";



    let sizeSellector = "";
    let sizeFormItem = "";
    if (sellectedItem !== null) {
        if (sellectedItem.sizes !== undefined && sellectedItem.sizes.length > 0) {
            sizeSellector = sellectedItem.sizes.map((item => {
                return <option key={item.id} value={item.id}>{item.label}</option>
            }))
            sizeFormItem = (
                <>
                    <div className="shop-form-item">
                        <h3> Wybierz rozmiar </h3>
                        <select value={form.size} className="shop-input-field"
                            onChange={e => setForm({ ...form, size: e.target.value })}>
                            {sizeSellector}
                        </select>
                    </div>
                    <h5>Jeśli żaden z obwodów szyi nie pasuje do twojego pupila, zamów najbliższy
                        i przyślij nam rozmiary emailem a my dostosujemy produkt.
                    </h5>
                </>
            )
        }
    }

    let scaledImage = "";
    if (scale) {
        scaledImage = (
            <div className="photos-box-overlay">
                <div className="photos-box-overlay-con">
                    <button className="btn-close" onClick={() => setScale(false)}>
                        <i className="fa-regular fa-circle-xmark "></i>
                    </button>
                    {/* <button className="btn-left" onClick={() => setSellectedItem(null)}>
                    <i className="fa-regular fa-circle-left "></i>
                </button> */}
                    <img className="photos-box-overlay-img" src={sellectedItem.images[sellectedPrev]} ></img>
                    {/* <button className="btn-right" onClick={() => setSellectedItem(null)}>
                    <i className="fa-regular fa-circle-right "></i>
                </button> */}
                </div>
            </div>
        );
    }



    return (
        <div className="shop">

            {scale ? scaledImage : ""}

            <div className="shop-details">
                <button className="btn-close" onClick={() => setSellectedItem(null)}>
                    <i className="fa-regular fa-circle-xmark "></i>
                </button>
                <div className='shop-item-card'>
                    <h2>{sellectedItem.title}</h2>
                    <div className="shop-item-photos-box">
                        <div className="photos-box-main">
                            <button className="btn-magnifier" onClick={() => setScale(true)}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                            <img className="photos-box-img" src={sellectedItem.images[sellectedPrev]} ></img>
                        </div>
                        <div className="photos-box-prev-container">
                            {prevContent}
                        </div>

                    </div>
                    <h3>{sellectedItem.short1}</h3>

                </div>
                <div className="shop-size-sellectors">
                    <form>
                        {/* <select value={form.size}
                                onChange={e => setForm({...form,size:e.target.value}  )}>
                                {sizeSellector}
                            </select> */}
                        {sizeFormItem}


                        {/* dog name */}
                        {err.dogName ? <div className='err-info'><h5 >Wpisz imię zwierzęcia, jeden wyraz</h5></div> : ""}
                        <div className="shop-form-item">
                            <h3> Imię zwerzęcia </h3>
                            <input className="shop-input-field" type="text" placeholder="np. Azor" name="name"
                                value={form.dogName} onChange={dogNameHandler} maxLength="25"
                            />
                        </div>
                    </form>
                    <div className="shop-detail-price">
                        <h2>{priceStr}</h2>
                    </div>

                    {orderButton}

                    <button className="btn shop-details-close-btn" onClick={() => setSellectedItem(null)}
                    >Anuluj</button>

                </div>
                <div className="shop-details-desc">
                    <div dangerouslySetInnerHTML={{ __html: sellectedItem.details }}></div>


                </div>


            </div>
        </div>
    )

}

export default ShopItemDetails;