
import './App.css';
import './page.css';
import {useEffect, useState} from 'react';
import {checkSession, logout} from './Api/userApi';
import UserPanel from './Components/UserPanel';
import MainPage from './pages/MainPage';
import WarningModal from './Components/WarningModal';
import FinderPanel from './Components/FinderPanel';

function App() {
  const[user, setUser] = useState({id:0, email:"", name:""});
  const [warningContent, setWarningContent] = useState(null);
  
  
  useEffect(()=>{
    async function getData(){
       const res = await checkSession(null);
       if(res === null){
        return;
       }
       if(res.errorCode===1){
        if(process.env.NODE_ENV === 'development'){
          console.log("user logged in");
        }
        setUser({...res.payload});
       }else if(res.errorCode===3){
        if(process.env.NODE_ENV === 'development'){
          console.log("user not logged in");
        }
        setUser({id:0, email:"", name:"", active:false});
       }else if(res.errorCode===2){
        if(process.env.NODE_ENV === 'development'){
          console.log("chcek session err");
        }
        setUser({id:0, email:"", name:"", active:false});
       }
     }
     getData();
  },[]);


  const logoutHandler =()=>{
    logout();
    setUser({...user, id:0});
  }


  return (
    <div className="App">
      { warningContent==null ? "" : <WarningModal data={warningContent}></WarningModal>}
      <MainPage user={user}></MainPage>
      <FinderPanel setWarning={setWarningContent}></FinderPanel>
      <UserPanel user={user} setUser={setUser} setWarning={setWarningContent}
        logoutHandler={logoutHandler}></UserPanel>
        {/* <RegistrationForm></RegistrationForm> */}
        {/* <LoginForm></LoginForm>
        */}
        
    </div>
  );
}

export default App;
