const WarningModal = ({data})=>{

    return(
        <div className="warning-modal-overlay">
            <div className="warning-modal-box">
                <div className="warning-modal-header">
                    <h3>{data.header}</h3>
                </div>
                <div className="warning-modal-content">
                    <h3>{data.content}</h3>
                </div>
                <div className="warning-modal-buttons">
                    <button className="btn" onClick={data.btnHandler}>{data.btnLabel} </button>
                </div>
            </div>
        </div>
    )
}

export default WarningModal;