
import { useState } from "react";
import { activate, sendActivationEmail } from "../Api/userApi";

const UserNotActivePanel = ({ user, setUser, setWarning }) => {
    const [act, setAct] = useState({ code: "" });

    const onChangeHandler = (e) => {
        setAct({ ...act, [e.target.name]: e.target.value });
    }

    const activateHandler = async (e) => {
        e.preventDefault();

        if (act.code.length !== 6) {
            setWarning(
                {
                    header: "Wpisz kod",
                    content: "Kod składa się z 6 znaków",
                    btnHandler: () => setWarning(null),
                    btnLabel: "ok"
                }
            );
            return;
        }
        const res = await activate(act);
 

        if (res === null) {
            setWarning(
                {
                    header: "Błąd aktywacji",
                    content: "Spróbuj późnej",
                    btnHandler: () => setWarning(null),
                    btnLabel: "ok"
                }
            );
        } else if (parseInt(res.errorCode) === 1) {
            setWarning(
                {
                    header: "Atywacja prawidłowa",
                    content: "",
                    btnHandler: () => setWarning(null),
                    btnLabel: "ok"
                }
            );
        } else {
            setWarning(
                {
                    header: "Błąd Aktywacji",
                    content: "Kod nieprawidłowy",
                    btnHandler: () => setWarning(null),
                    btnLabel: "ok"
                }
            );
        }


        if (res === null) {
            return;
        }
        if (parseInt(res.errorCode) === 1) {
            setUser({ ...user, active: 'true' });
        }
    }

    const sendActivationHandler = async () => {

        const res = await sendActivationEmail(user.email);
        if (res === null) {
            setWarning(
                {
                    header: "Błąd wysyłania wiadomości",
                    content: "Spróbuj późnej",
                    btnHandler: () => setWarning(null),
                    btnLabel: "ok"
                }
            );
        } else if (parseInt(res.errorCode) === 1) {
            setWarning(
                {
                    header: "Wiadomość zosała wysłana",
                    content: "Sprawdź skrzynkę oraz folder spam",
                    btnHandler: () => setWarning(null),
                    btnLabel: "ok"
                }
            );
        } else {
            setWarning(
                {
                    header: "Błąd wysyłania wiadomości",
                    content: "Spróbuj późnej",
                    btnHandler: () => setWarning(null),
                    btnLabel: "ok"
                }
            );
        }
    }

    return (
        <div>
            <div className="container">
                <div className="form-box box" style={{padding:20}}>
                    <header>Aktywacja</header>
                    <hr />
                    <form onSubmit={activateHandler}>
                        <div className="form-box_">
                            <div className="form-box-info" style={{ textAlign: 'center' }}>
                                <h2>Witaj {user.name}</h2>
                                <h3 style={{ textAlign: 'justify' }}>Dziękujemy za rejestrację w naszym systemie. <br />Abyś mógł
                                    rozpocząć korzystanie wpisz kod, który wysłaliśmy na Twój
                                    adres email {user.email}
                                </h3>
                            </div>
                            <div className="input-container">
                                <i className="fa fa-shield icon"></i>
                                <input className="input-field" type="text" placeholder="XXXXXX" name="code"
                                    maxLength="6" onChange={onChangeHandler} value={act.code} />
                            </div>

                        </div>
                        <center><input type="submit" name="register" id="submit" value="Aktywuj" className="btn" /></center>


                        <div className="links">
                            Jeśli nie otrzymałeś od nas wiadomości sprawdź w folderze spam lub
                            <a onClick={sendActivationHandler}>Wyślij ponownie</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserNotActivePanel;