import { useState } from "react";
import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import UserNotActivePanel from "./UserNotActivePanel";
import PasswordResetForm from "./PasswordResetForm";
import ChangePassForm from "./ChangePassForm";
import MessageBox from "./MessageBox";

const UserPanel = ({user, setUser, setWarning, logoutHandler})=>{
    const [signUp, setSignUp] = useState(false);
    const [recovery,setRecovery] = useState(false);
    const [passHange,setPassHange] = useState(false);
    const [panelMin, setPanelMin] = useState(true);


    const passChangeTogleHandler = ()=>{
        if(passHange){
            setPassHange(false);
        }else{
            setPassHange(true);
        }
    }

    let notLoggedInContent = "";

    if(signUp){    
        notLoggedInContent=  <RegistrationForm setUser={setUser} setSignUp = {setSignUp} setWarning={setWarning}></RegistrationForm>;
    }else if(recovery){
        notLoggedInContent=<PasswordResetForm setRecovery = {setRecovery} setWarning={setWarning}></PasswordResetForm>;
    }else{
        notLoggedInContent= <LoginForm setUser={setUser} setSignUp = {setSignUp} setRecovery={setRecovery} setWarning={setWarning}></LoginForm>;
    }

    const welcomeMessage = (
        <div className="user-panel-info-box">
        Dziękujemy że zarejestrowałeś się w naszym serwisie.<br/>
        Już teraz możesz wykorzystać kod wygenerowany specjalnie
        dla twojego pupila. 
        <br/>Nasz system będzie obsługiwał
        zgłoszenia związane z twoim kodem do daty widocznej pod
        statusem kodu. 
        <br/>Dokonaj zakupu dowolnego produktu w naszym sklepie
        aby przedłużyć subskrypcję.  
    </div>
    )


    if(user.id < 1){
        if(panelMin){
            return (
                <div className="user-panel user-panel-min">
                    <i className="fa fa-user "></i>
                    <button className="btn" onClick={()=>setPanelMin(false)}>Zaloguj się</button>
                </div>
            )
        }else{
                return (
                    <div className="user-panel user-panel-max">
                        <button className="btn-close btn" onClick={()=>setPanelMin(true)}>
                            <i className="fa-regular fa-circle-xmark "></i>
                        </button>
                        {notLoggedInContent}
                    </div>
                )
        }
    }else{
        if(panelMin){
            return (
            <div className="user-panel user-panel-min">
                {/* <i className="fa fa-user "></i> */}
                <div style={{display:'flex',width:'160px',height:'18px', overflow:'hidden',
                                justifyContent:'center'}}>
                                <i  style={{fontSize:'14px'}}className="fa fa-user "></i>    
                                <div><h2 style={{fontSize:'14px'}}>{user.email}</h2></div> 
                            </div>
                <button className="btn" onClick={()=>setPanelMin(false)}>Mój panel</button>
            </div>
            )
        }else{
      
            if(user.active === 'false'){
              
                return (
                    <>
                    <div className="user-panel user-panel-max">
                    <button className="btn-close btn" onClick={()=>setPanelMin(true)}>
                            <i className="fa-regular fa-circle-xmark "></i>
                        </button>
                    <UserNotActivePanel user={user} setUser={setUser} setWarning={setWarning}></UserNotActivePanel>
                    <button style={{marginTop:50, fontSize:18}} 
                        onClick={logoutHandler} className="btn-menu-item btn">
                            <i className="fa fa-door-open "></i> Wyloguj się</button>
                    </div>
                    </>
                )
            }else{
                return (
                    <div className="user-panel user-panel-max">
                    
                        <button className="btn-close btn" onClick={()=>setPanelMin(true)}>
                            <i className="fa-regular fa-circle-xmark "></i>
                        </button>

                        <div className="user-panel-menu">
                            <div style={{display:'flex',width:'160px',height:'18px', overflow:'hidden',
                                justifyContent:'center'}}>
                                    <i className="fa fa-user "></i>
                                <div><h2 style={{fontSize:'14px'}}>{user.email}</h2></div> 
                            </div>
                            <div className="user-panel-code-box">
                                <h3>Twój kod</h3>
                                <h2>{user.code}</h2>
                                <div className="status-container">
                                    <h4>Status:</h4>
                                    <h4 className="account-status green">Aktywny</h4>
                                </div>
                                <div style ={{marginTop:'10px', fontSize:'14px'}} className="status-container">

                                    <h4 className="account-status">do:{user.subscription.expDate}</h4>
                                </div>
                            </div>
                           
                            <button className="btn-menu-item" onClick={passChangeTogleHandler}>Zmiana hasła</button>
                            <button onClick={logoutHandler} className="btn-menu-item">
                            <i className="fa fa-door-open "></i> Wyloguj się</button>
                        </div>
                        <div className="user-panel-right">
                            {parseInt(user.subscription.status)===1 ? welcomeMessage:""} 
                            <MessageBox></MessageBox> 
                            {passHange?<ChangePassForm user={user} setWarning={setWarning} 
                            setPassHange={setPassHange}></ChangePassForm> : ""}
                        </div>
                    </div>
                )
            }
        }
    }
    
}

export default UserPanel;