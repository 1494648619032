import { useEffect, useState } from 'react';
import { passwordReset, passwordResetVerify } from '../Api/userApi';

const PasswordResetForm = (props) => {
  const [user, setUser] = useState({ email: "", code: "" });
  const [warningContent, setWarningContent] = useState(null);
  const [err, setErr] = useState({ email: false, code: false });
  const [sent, setSent] = useState(false);

  const onChangeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const sendCodeHandler = async (e) => {
    e.preventDefault();

    const tmpErr = { email: false };
    if (validateEmail(user.email) === null) {
      tmpErr.email = true;
      setErr(tmpErr);
      return;
    }
    setErr(tmpErr);

    const res = await passwordReset(user);
    if (res === null) {
      props.setWarning(
        {
          header: "Błąd serwera",
          content: "Spróbuj późnej",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
      return;
    } else if (parseInt(res.errorCode) === 1) {
      //setSent(true);
      props.setWarning(
        {
          header: "Wygenerowano kod jednorazowy",
          content: "Na podany adres email wysłaliśmy jednorazowy kod logowania. Zaloguj się a następnie skorzystaj z funkcji zmiany hasła",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
      props.setRecovery(false);
    } else {
      props.setWarning(
        {
          header: "Błąd serwera",
          content: "Spróbuj późnej",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  //if(!sent){
  return (
    <>
      <div className="container">
        <div className="form-box box">
          <header>Odzyskiwanie dostępu</header>
          <hr />

          <form onSubmit={sendCodeHandler}>
            <div className="form-box_">
              <div className="form-box-info" style={{ textAlign: 'center' }}>

                <h3 style={{ textAlign: 'justify' }}>Podaj adres email użyty przy rejestracji konta.
                </h3>
              </div>

              {err.email ? <div className='err-info'><h5 >popraw adres email</h5></div> : ""}
              <div className="input-container" >

                <i className="fa fa-envelope icon"></i>
                <input className="input-field" type="text" placeholder="Adres email" name="email"
                  onChange={onChangeHandler} value={user.email} />
              </div>


            </div>
            <center><input type="submit" name="register" style={{marginLeft:15}}
            id="submit" value="Wyślij" className="btn" /></center>


            <div className="links">
              <a onClick={() => props.setRecovery(false)}>Zaloguj się</a>
            </div>

          </form>
        </div>
      </div>
    </>
  )

  //}


  // return (
  //     <>
  //         <div className="container">
  //         <div className="form-box box">
  //         <header>Resetowanie hasła</header>
  //         <hr/>

  //         <form onSubmit={validateCodeHandler}>
  //         <div className="form-box">
  //         <div className="form-box-info" style={{textAlign:'center'}}>

  //         <h3 style={{textAlign:'justify'}}>Podaj adres email użyty przy rejestracji konta. 
  //         </h3>
  //             </div>

  //         { err.email? <div className='err-info'><h5 >popraw adres email</h5></div> : "" }
  //         <div className="input-container">

  //           <i className="fa fa-envelope icon"></i>
  //           <input className="input-field" type="text" placeholder="Adres email" name="email" 
  //             onChange={onChangeHandler} value={user.email} readOnly/>
  //         </div>

  //         <div className="form-box-info" style={{textAlign:'center'}}>

  //         <h3 style={{textAlign:'justify'}}>Podaj kod otrzymany w wiadomości email wysłanej na podany adres 
  //         </h3>
  //             </div>
  //         { err.code? <div className='err-info'><h5>kod powinien składać się z 10 zanków</h5></div> : "" }
  //         <div className="input-container">
  //           <i className="fa fa-lock icon"></i>
  //           <input className="input-field" type="text" placeholder="Kod" name="code" 
  //             onChange={onChangeHandler} value={user.code}/>
  //           {/* <i className="fa fa-eye icon toggle"></i> */}
  //         </div>


  //         </div>
  //         <center><input type="submit" name="register" id="submit" value="Zatwierdź" className="btn"/></center>


  //       <div className="links">
  //         <a onClick={()=>setSent(false)}>Spróbuj ponownie</a>
  //       </div>
  //       <div className="links">
  //         <a onClick={()=>props.setRecovery(false)}>Zaloguj się</a>
  //       </div>

  //         </form>
  //         </div>
  //         </div>
  //     </>
  // )
}

export default PasswordResetForm;